

/* about */
.menu_header-sec {
    background-image:linear-gradient(rgb(72,0,72,0.8), rgb(19,72,72,0.8)), url(../../../public/images/about-us.jpg) !important;
    background-repeat: no-repeat !important;
    background-size: cover;
    background: rgb(247,247,247);
/* background: -moz-linear-gradient(180deg, rgba(247,247,247,1) 34%, rgba(0,0,0,0.4205882181974352) 75%) !important;
background: -webkit-linear-gradient(180deg, rgba(247,247,247,1) 34%, rgba(0,0,0,0.4205882181974352) 75%) !important;
background: linear-gradient(180deg, rgba(247,247,247,1) 34%, rgba(0,0,0,0.4205882181974352) 75%) !important; */
  }

  .menu_header-sec nav {
    background: transparent !important;
    box-shadow: none  !important;
    padding: 20px 0px 40px !important;
  }
  .heading-text {
    font-size: 25px !important;
    font-weight: 500;
    color: #252b42;
    padding-bottom: 15px;
  }
  .peragraph-text {
    font-size: 16px;
    color: #737373;
  }
/* about-end */  

/* contact */
.contact-banner{
  background-image:linear-gradient(rgb(18,0,72,0.8), rgb(98,72,72,0.8)), url("/public/images/contact-us-1.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: rgb(247,247,247);
/* background: -moz-linear-gradient(180deg, rgba(247,247,247,1) 34%, rgba(0,0,0,0.4205882181974352) 75%) !important;
background: -webkit-linear-gradient(180deg, rgba(247,247,247,1) 34%, rgba(0,0,0,0.4205882181974352) 75%) !important;
background: linear-gradient(180deg, rgba(247,247,247,1) 34%, rgba(0,0,0,0.4205882181974352) 75%) !important; */
}
.contact_details {
  text-align: center;
  padding: 20px 0;
}
.contact_box {
  background: #473655 !important;
  margin-top: 50px;
  padding: 40px 0;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
}
.contact_button button {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  background: var(--primary-color, #FFA62B) !important;
  padding: 9px 29.5px;
  margin: 0px;
  font-size: 15px;
}
/* contact-end */